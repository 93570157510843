import React from 'react'
import ContactForm from '../components/contact/Contact'

function ContactPage() {
  return (
    <div>
      <ContactForm />

    </div>
  )
}

export default ContactPage
